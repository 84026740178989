import posthog from 'posthog-js';

const openHubSpotConversations = () => {
  window.HubSpotConversations?.widget.load();
  window.HubSpotConversations?.widget.open();

  window.HubSpotConversations?.on('widgetClosed', () => {
    window.HubSpotConversations.widget.remove();
    window.HubSpotConversations.off('widgetClosed', null);
  });
};

const getTools = () => [
  {
    type: 'function',
    function: {
      name: 'contactSales',
      description:
        "Detect if there is buyer intent or the customer is asking about pricing information or Ably's paid plans.",
      parameters: {
        type: 'object',
        properties: {
          aiAnnotations: {
            type: 'object',
            properties: {
              answerConfidence: {
                anyOf: [
                  { type: 'string', const: 'very_confident' },
                  { type: 'string', const: 'somewhat_confident' },
                  { type: 'string', const: 'not_confident' },
                  { type: 'string', const: 'no_sources' },
                ],
              },
            },
            required: ['answerConfidence'],
          },
        },
        required: ['aiAnnotations'],
      },
    },
    renderMessageButtons: ({ args }) => {
      const confidence = args.aiAnnotations.answerConfidence;
      if (confidence === 'very_confident' || confidence === 'somewhat_confident') {
        return [
          {
            label: 'Talk to Sales',
            icon: { builtIn: 'LuUsers' },
            action: {
              type: 'invoke_callback',
              callback: () => {
                window.history.pushState({}, '', '?chat-type=product');
                openHubSpotConversations();
              },
              shouldCloseModal: true,
            },
          },
        ];
      }
      return [];
    },
  },
];

const transformSource = (source) => {
  const { url, breadcrumbs } = source;
  if (!url) return source;
  try {
    const { pathname } = new URL(url);
    const urlPatterns = [
      { pattern: '/docs', tabName: 'Docs' },
      { pattern: '/blog', tabName: 'Blog' },
      { pattern: 'faqs.ably.com', tabName: 'FAQs' },
    ];

    const matchingPattern = urlPatterns.find(({ pattern }) => pathname.startsWith(pattern) || url.includes(pattern));

    if (matchingPattern) {
      const { tabName } = matchingPattern;
      return {
        ...source,
        tabs: [
          [
            tabName,
            {
              breadcrumbs: breadcrumbs[0] === tabName ? breadcrumbs.slice(1) : breadcrumbs,
            },
          ],
        ],
      };
    }
    return source;
  } catch (error) {
    return source;
  }
};

const prefilledSearchText = () => {
  const queryParam = new URLSearchParams(window.location.search).get('q');

  return queryParam || '';
};
const searchSettings = {
  defaultQuery: prefilledSearchText(),
  tabs: ['All', 'Docs', 'Blog', 'FAQs', 'GitHub'],
};
const theme = {
  styles: [
    {
      key: 'custom-style',
      type: 'style',
      value: `css
            .ikp-ai-chat-message-toolbar {
              flex-wrap: wrap;
              justify-content: flex-end;
            }
            .ikp-ai-chat-message-tool-actions {
              width: 100%;
            }
            .ikp-ai-chat-message-tool-action {
              background: #03020d;
              color: white;
              height: 36px;
              font-size: 15px;
              border: none;
              margin: 0px auto;
              margin-bottom: 8px;
              padding: 12px 24px;
              height: auto;
              gap: 12px;
            }
            .ikp-ai-chat-message-tool-action > .ikp-icon {
              font-size: 18px;
            }
            .ikp-ai-chat-message-tool-action:hover:not([disabled]) {
              background: #2c3344;
              color: white;
            }
          `,
    },
  ],
};
const baseSettings = { ...window.inkeepBaseSettings, transformSource, theme };
const config = () => ({
  baseSettings,
  label: 'Ask Ably',
  aiChatSettings: {
    organizationDisplayName: 'Ably',
    aiAssistantAvatar:
      'https://storage.googleapis.com/organization-image-assets/ably-botAvatarSrcUrl-1744182878600.png',
    getHelpOptions: [
      {
        name: 'Request a meeting',
        action: {
          type: 'open_link',
          url: 'https://meetings.hubspot.com/cameron-michie/ably-demo',
        },
        isPinnedToToolbar: true,
      },
      {
        name: 'Chat with support',
        action: {
          type: 'invoke_callback',
          callback: () => {
            openHubSpotConversations();
          },
          shouldCloseModal: true,
        },
        isPinnedToToolbar: true,
      },
    ],
    exampleQuestions: ['What is a channel?', 'How do I authenticate with Ably?', 'How to manage user status?'],
    shouldShowCopyChatButton: true,
    ...(posthog.isFeatureEnabled('inkeep-intent') ? { getTools } : {}),
  },
  searchSettings,
});

posthog.onFeatureFlags(() => {
  window.Inkeep.ChatButton({
    ...config(),
  });
});

let searchWidget;
const handleSearchChange = (isOpen) => {
  searchWidget.update({ modalSettings: { isOpen } });
};
const modalSettings = {
  modalSettings: {
    defaultView: 'SEARCH',
    onOpenChange: handleSearchChange,
  },
};
searchWidget = window.Inkeep.ModalSearchAndChat({ ...config(), ...modalSettings });
const searchButton = { element: document.querySelector("[data-id='window.Inkeep-search']"), event: 'click' };
const searchInput = { element: document.querySelector("[data-id='window.Inkeep-search-input']"), event: 'focus' };

[searchButton, searchInput].forEach((input) => {
  input.element?.addEventListener(input.event, () => {
    handleSearchChange(true);
  });
});
